import {ISoundCloudPlaylistItem} from "../components/SoundCloud/SoundCloud.interface";

export const appMusic: ISoundCloudPlaylistItem[] = [
  {
    title: 'Ego Trippin ft. Shabba & Shotta',
    soundcloudId: '726893203',
    link: 'https://soundcloud.com/officialmcshabba/ego-shotta-shabba'
  },
  {
    title: 'SHABBA D - FREE STYLE - TRACK BY EGO TRIPPIN',
    soundcloudId: '319954889',
    link: 'https://soundcloud.com/officialmcshabba/shabba-d-free-style-track-by-ego-trippin-mp3'
  },
  // {
  //   title: 'SHABBA D - MUSIC MONOPOLY - PROD BY DJ SLY',
  //   soundcloudId: '319954415',
  //   link: 'https://soundcloud.com/officialmcshabba/shabba-d-music-monopoly-prod-by-dj-sly'
  // },
  {
    title: 'MC SHABBA\'S BASH 2017',
    soundcloudId: '317697224',
    link: 'https://soundcloud.com/officialmcshabba/mc-shabbas-bash-2017'
  },
  // {
  //   title: 'SHABBA - FREE STYLE - PROD BY DJ SLY',
  //   soundcloudId: '317696316',
  //   link: 'https://soundcloud.com/officialmcshabba/shabba-free-style-prod-by-dj-sly-free-download'
  // },
  // {
  //   title: 'MC SHABBA JUNGLIST GUY (PROD BY DJ SLY & PACSO',
  //   soundcloudId: '289227273',
  //   link: 'https://soundcloud.com/officialmcshabba/mc-shabba-junglist-guy-prod-by-dj-sly-pacso'
  // },
  // {
  //   title: 'MC SHABBA - WHEN WE TURN UP (PROD BY DJ SLY & PACSO',
  //   soundcloudId: '289226561',
  //   link: 'https://soundcloud.com/officialmcshabba/mc-shabba-when-we-turn-up-prod-by-dj-sly-pacso'
  // }
];
