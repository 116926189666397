import moment from 'moment';

export class DateHelpers {

  public utcDate = 'YYYY-MM-DD';
  public utcDateTime = 'YYYY-MM-DDTHH:mm';

  public getNow(format = this.utcDate): string {
    return moment().format(format);
  }

  public isFuture(datetime: string, format = this.utcDate): boolean {
    return moment(this.getNow(format)).isSameOrBefore(moment(datetime, format));
  }

  public isPast(datetime: string, format = this.utcDate): boolean {
    return !this.isFuture(datetime, format);
  }

}
