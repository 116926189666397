import * as React from 'react';
import './SoundCloudProgress.css';
import { ISoundCloudProgressProps } from './SoundCloudProgress.interface';

class SoundCloudProgress extends React.Component<ISoundCloudProgressProps, ISoundCloudProgressProps> {

  public container: React.RefObject<any>;
  public tracker: React.RefObject<any>;

  constructor(props: ISoundCloudProgressProps) {
    super(props);
    this.state = {
      title: '',
      loaded: false,
      position: 0,
      skipTo: (shouldPlay: boolean, position: number) => null
    };

    this.container = React.createRef();
    this.tracker = React.createRef();

  }

  public round(num: number): number {
    return parseFloat(num.toFixed(1));
  }

  public componentWillReceiveProps(nextProps: ISoundCloudProgressProps) {
    if (nextProps.loaded !== this.props.loaded || nextProps.title !== this.props.title) {
      this.setState({title: !nextProps.loaded ? 'Loading...' : nextProps.title});
    }

    if (nextProps.position !== this.state.position) {
      const position = this.round(nextProps.position * 100);
      this.setState({position});
      localStorage.setItem('audioPlayerProgress', nextProps.position.toString());
    }
  }

  public skipTo = (event: React.MouseEvent) => {
    if (!this.props.loaded) {
      return
    }
    this.props.skipTo(true, event.nativeEvent.offsetX / this.container.current.offsetWidth);
  };

  public render() {
    return (
      <div className="SoundCloudProgress"
           ref={this.container}
           onClick={this.skipTo}>
        <div className="SoundCloudProgress__bar" style={{ width: `${this.state.position}%` }}/>
        <p className="SoundCloudProgress__title">{this.state.title}</p>
      </div>
    )
  }
}

export default SoundCloudProgress;