"use strict";
exports.__esModule = true;
exports.appData = {
    ticketUrl: 'https://www.deftickets.co.uk/events/damandbass2019',
    ticketUrlEu: 'http://www.ticketmaster.nl/search/?fbclid=IwAR2W0yGr9H_qtOevVVEf-mW5dqNXZKNcGWSMHnkMQxVJzbGS9_K2552QnIs&keyword=breakin+science',
    domain: 'shabbadan.com',
    internalLinks: {
        home: '/',
        information: '/info'
    },
    social: {
        facebook: 'https://www.facebook.com/shabbadan1/',
        instagram: 'https://www.instagram.com/shabbadan/',
        twitter: 'https://twitter.com/shabbadan/'
    }
};
