import * as React from 'react';
import {appEventDispatcher} from "../../app/app-events";
import {AppCustomEvents} from "../../_enums/app-custom-events.enum";
import Page from "../../components/Page/Page";
import * as meta from './Home.meta';
import Container from "../../components/Container/Container";
import MainContent from "../../components/MainContent/MainContent";
import Image from "../../components/Image/Image";
import SocialList from "../../components/SocialList/SocialList";
import {appData} from "../../_data/app-data";
import Heading from "../../components/Heading/Heading";
import {HeadingSizes} from "../../components/Heading/Heading.enum";
import './Home.css';

class Home extends React.Component {

  constructor(props: any) {
    super(props);
  }

  public componentDidMount(): void {
    appEventDispatcher(AppCustomEvents.BackgroundColourChange, '#020220');
  }

  public render() {
    return (
    <div className="Home">
      <Page {...meta.default}>
        <MainContent bgImage={`assets/img/bg/home.png`} alignItems='center'>
          <div className={`Home__content`}>
            <Image src="/assets/img/logo-nav.png"
                   alt="MC Shabba"
                   className={`Home__logo`}/>
            <Heading text={`One of the UK's pioneering drum&bass MC's`} size={HeadingSizes.Size4}></Heading>
            <SocialList {...appData.social}/>
          </div>
        </MainContent>
      </Page>
    </div>
    )
  }
}

export default Home;
