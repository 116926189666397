import * as React from 'react';
import './Close.css';
import { ICloseProps } from './Close.interface';

class Close extends React.Component<ICloseProps> {

  protected constructor(props: ICloseProps) {
    super(props);
  }


  public render() {
    return (
      <div className={`Close`} onClick={this.props.clickFn}>
        <div className="Close__layer1"/>
        <div className="Close__layer2"/>
      </div>
    );
  }
}

export default Close;