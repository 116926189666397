import * as React from 'react';
import {appEventDispatcher} from "../../app/app-events";
import {AppCustomEvents} from "../../_enums/app-custom-events.enum";
import * as meta from "../Music/Music.meta";
import Page from "../../components/Page/Page";
import MainContent from "../../components/MainContent/MainContent";
import Heading from "../../components/Heading/Heading";
import SoundcloudEmbed from "../../components/SoundcloudEmbed/SoundcloudEmbed";
import './Music.css';
import {triggerSoundCloud} from "../../components/SoundCloud/app-soundcloud";
import {ISoundCloudPlaylistItem} from "../../components/SoundCloud/SoundCloud.interface";
import {appMusic} from "../../_data/app-music";

class Music extends React.Component {

  public music: ISoundCloudPlaylistItem[] = appMusic;

  constructor(props: any) {
    super(props);
  }

  public componentDidMount(): void {
    appEventDispatcher(AppCustomEvents.BackgroundColourChange, '#1f2838');
  }

  public playPause = (soundcloudId: string): void => {
    debugger
    const item = this.music.find(m => m.soundcloudId === soundcloudId);
    if (!item) {
      return
    }

    triggerSoundCloud({
      playingId: item.soundcloudId,
      title: item.title,
      secretId: item.secretId,
      shouldPlay: true,
      progress: 0
    });
  };

  public render() {
    return (
    <div className="Music">
      <Page {...meta.default}>
        <MainContent bgImage={`assets/img/bg/music2.png`}>

          <Heading text={`Music`} level={`h1`}/>
          <p>Listen to some of the latest music from MC Shabba:</p>

          <iframe src="https://open.spotify.com/embed/artist/3mNLI3NXEFH2CoYFDRN2vr" className="playlist" width="100%"
                  height="500"
                  frameBorder="0" allow="encrypted-media"/>

          <div>
            {this.music.map(item => {
              return (<div className={`Music__item`}>
                <div>
                  <img className={`Music__item__img`}
                       src={`/assets/img/soundcloud/${item.soundcloudId}.jpg`}/>
                </div>
                <div className={`Music__item__content`}>
                  <h2 className={`Music__item__heading`}>{item.title}</h2>
                  <a key={item.soundcloudId}
                     className={`Music__button`}
                     onClick={this.playPause.bind(this, item.soundcloudId)}>Listen now</a>
                  <a className={`Music__link`} href={item.link} target={`_blank`}>or listen on Soundcloud</a>
                </div>
              </div>)
            })}
          </div>

        </MainContent>
      </Page>
    </div>
    )
  }
}

export default Music;
