"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
var React = require("react");
require("./Container.css");
var Container = /** @class */ (function (_super) {
    __extends(Container, _super);
    function Container(props) {
        return _super.call(this, props) || this;
    }
    Container.prototype.render = function () {
        var modifiers = !this.props.modifiers ? '' : this.props.modifiers
            .map(function (mod) { return "Container--" + mod; })
            .join(' ');
        return (React.createElement("div", { className: "Container " + modifiers + " " + (this.props.className || '') }, this.props.children));
    };
    return Container;
}(React.Component));
exports["default"] = Container;
