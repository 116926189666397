import * as React from 'react';
import { ISocialIconProps } from './SocialIcon.interface';
import './SocialIcon.css';

class SocialIcon extends React.Component<ISocialIconProps> {

  public facebook =
    <div className="SocialIcon__item SocialIcon__facebook">
      <svg height="43px" viewBox="0 0 43 43" width="43px" version="1.1" y="0px" x="0px">
        <path
          d="m27.313 17.516h-3.959v-2.596c0-0.973 0.645-1.2 1.104-1.2h2.788v-4.285l-3.844-0.017c-4.269 0-5.241 3.195-5.241 5.242v2.854h-2.467v4.415h2.467v12.486h5.191v-12.486h3.502l0.459-4.413z"
          fill="#fff"/>
      </svg>
    </div>;

  public instagram =
    <div className="SocialIcon__item SocialIcon__instagram">
      <svg height="43px" viewBox="0 0 43 43" width="43px" version="1.1" y="0px" x="0px">
        <path
          d="M21.49,13.88a7.59,7.59,0,1,0,7.59,7.59,7.59,7.59,0,0,0-7.59-7.59Zm0,12.52a4.93,4.93,0,1,1,4.93-4.93,4.93,4.93,0,0,1-4.93,4.93Z"
          fill="#fff"/>
        <circle cx="29.38" cy="13.6" r="1.77" fill="#fff"/>
        <path
          d="M35.5,11.8a7.56,7.56,0,0,0-4.32-4.32,10.85,10.85,0,0,0-3.59-.69C26,6.72,25.51,6.7,21.5,6.7s-4.52,0-6.09.09a10.85,10.85,0,0,0-3.59.69A7.56,7.56,0,0,0,7.5,11.8a10.85,10.85,0,0,0-.69,3.59c-.07,1.58-.09,2.08-.09,6.09s0,4.52.09,6.09a10.85,10.85,0,0,0,.69,3.59,7.56,7.56,0,0,0,4.32,4.32,10.85,10.85,0,0,0,3.59.74c1.58.07,2.08.09,6.09.09s4.52,0,6.09-.09a10.85,10.85,0,0,0,3.59-.69A7.56,7.56,0,0,0,35.5,31.2a10.85,10.85,0,0,0,.69-3.59c.07-1.58.09-2.08.09-6.09s0-4.52-.09-6.09A10.85,10.85,0,0,0,35.5,11.8Zm-2,15.65A8.18,8.18,0,0,1,33,30.22,4.9,4.9,0,0,1,30.18,33a8.18,8.18,0,0,1-2.74.51c-1.56.07-2,.09-6,.09s-4.41,0-6-.09A8.18,8.18,0,0,1,12.7,33a4.89,4.89,0,0,1-2.82-2.81,8.18,8.18,0,0,1-.51-2.74c-.07-1.56-.09-2-.09-6s0-4.41.09-6a8.18,8.18,0,0,1,.51-2.77A4.89,4.89,0,0,1,12.7,9.9a8.18,8.18,0,0,1,2.74-.51c1.56-.07,2-.09,6-.09s4.41,0,6,.09a8.18,8.18,0,0,1,2.74.51A4.9,4.9,0,0,1,33,12.71a8.18,8.18,0,0,1,.51,2.74c.07,1.56.09,2,.09,6s0,4.43-.07,6Z"
          fill="#fff"/>
      </svg>
    </div>;

  public twitter =
    <div className="SocialIcon__item SocialIcon__twitter">
      <svg height="43px" viewBox="0 0 43 43" width="43px" version="1.1" y="0px" x="0px">
        <path
          d="m31.49 17.511c0.006 0.214 0.01 0.429 0.01 0.641 0 6.542-4.973 14.076-14.076 14.076-2.797 0-5.398-0.813-7.59-2.222 0.391 0.047 0.785 0.075 1.182 0.075 2.32 0 4.453-0.793 6.148-2.123-2.168-0.039-3.994-1.47-4.623-3.436 0.303 0.06 0.609 0.09 0.932 0.09 0.449 0 0.891-0.062 1.305-0.172-2.264-0.453-3.973-2.453-3.973-4.854v-0.062c0.666 0.369 1.43 0.594 2.244 0.62-1.332-0.887-2.205-2.403-2.205-4.118 0-0.909 0.244-1.76 0.67-2.491 2.439 2.997 6.088 4.965 10.199 5.173-0.086-0.364-0.127-0.741-0.127-1.127 0-2.733 2.219-4.951 4.953-4.951 1.422 0 2.703 0.604 3.611 1.564 1.127-0.222 2.186-0.633 3.143-1.203-0.371 1.158-1.158 2.126-2.18 2.74 1.002-0.121 1.957-0.385 2.842-0.778-0.658 0.991-1.498 1.861-2.465 2.558z"
          fill="#fff"/>
      </svg>
    </div>;

  public soundcloud =
    <div className="SocialIcon__item SocialIcon__soundcloud">
      <svg height="43px" viewBox="0 0 43 43" width="43px" version="1.1" y="0px" x="0px">
        <path className="st0" d="M6.3,22.8c-0.1,0-0.1,0.1-0.1,0.1l-0.2,1.7l0.2,1.7c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.1-0.1l0,0v0
          c0,0,0,0,0,0l0.3-1.7l-0.3-1.7C6.4,22.9,6.3,22.8,6.3,22.8z"/>
        <path className="st0" d="M7.5,21.9c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1c0,0-0.3,2.8-0.3,2.8l0.3,2.7c0,0.1,0.1,0.1,0.1,0.1
          c0.1,0,0.1-0.1,0.1-0.1l0.3-2.7L7.5,21.9z"/>
        <path className="st0" d="M12.4,19.3c-0.1,0-0.2,0.1-0.2,0.2l-0.2,5.2l0.2,3.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2v0l0.3-3.3
          l-0.3-5.2C12.6,19.4,12.5,19.3,12.4,19.3z"/>
        <path className="st0" d="M9.9,21.1c-0.1,0-0.2,0.1-0.2,0.2l-0.3,3.4L9.7,28c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2l0.3-3.3
          l-0.3-3.4C10,21.2,10,21.1,9.9,21.1z"/>
        <path className="st0" d="M14.9,28.2c0.2,0,0.3-0.1,0.3-0.3l0.2-3.3l-0.2-6.9c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3
          c0,0-0.2,6.9-0.2,6.9l0.2,3.3C14.6,28.1,14.8,28.2,14.9,28.2z"/>
        <path className="st0" d="M20.1,28.3c0.2,0,0.4-0.2,0.4-0.4v0v0l0.2-3.2l-0.2-7.9c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4
          l-0.1,7.9c0,0,0.1,3.2,0.1,3.2C19.7,28.1,19.9,28.3,20.1,28.3z"/>
        <path className="st0" d="M17.5,28.2c0.2,0,0.3-0.1,0.3-0.3v0l0.2-3.2l-0.2-6.9c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3L17,24.7
          l0.2,3.2C17.2,28.1,17.3,28.2,17.5,28.2z"/>
        <path className="st0" d="M11.1,28.2c0.1,0,0.2-0.1,0.2-0.2l0.3-3.3l-0.3-3.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2l-0.3,3.2
          l0.3,3.3C10.9,28.1,11,28.2,11.1,28.2z"/>
        <path className="st0" d="M8.6,28c0.1,0,0.1-0.1,0.2-0.2l0.3-3.2l-0.3-3.3c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.1,0.1-0.2,0.2
          c0,0-0.3,3.3-0.3,3.3l0.3,3.2C8.5,28,8.6,28,8.6,28z"/>
        <path className="st0" d="M18.8,17.7c-0.2,0-0.4,0.2-0.4,0.4l-0.2,6.7l0.2,3.2c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4v0l0.2-3.2
          L19.2,18C19.2,17.8,19,17.7,18.8,17.7z"/>
        <path className="st0" d="M13.6,28.3c0.1,0,0.3-0.1,0.3-0.3l0.3-3.3l-0.3-6.3c0-0.1-0.1-0.3-0.3-0.3c-0.1,0-0.3,0.1-0.3,0.3
          c0,0-0.2,6.3-0.2,6.3l0.2,3.3C13.4,28.1,13.5,28.3,13.6,28.3z"/>
        <path className="st0" d="M16.5,27.9L16.5,27.9l0.2-3.3l-0.2-7.1c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3l-0.2,7.1l0.2,3.3
          c0,0.2,0.1,0.3,0.3,0.3C16.4,28.3,16.5,28.1,16.5,27.9L16.5,27.9z"/>
        <path className="st0" d="M33.3,20.6c-0.5,0-1,0.1-1.5,0.3c-0.3-3.5-3.2-6.2-6.7-6.2c-0.9,0-1.7,0.2-2.5,0.5c-0.3,0.1-0.4,0.2-0.4,0.5
          v12.2c0,0.2,0.2,0.4,0.4,0.5c0,0,10.6,0,10.6,0c2.1,0,3.8-1.7,3.8-3.8S35.4,20.6,33.3,20.6z"/>
        <path className="st0" d="M21.4,15.6c-0.2,0-0.4,0.2-0.4,0.4l-0.2,8.6l0.2,3.1c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4v0l0.2-3.1
          L21.8,16C21.8,15.8,21.7,15.6,21.4,15.6z"/>
      </svg>
    </div>;


  protected constructor(props: ISocialIconProps) {
    super(props);
  }

  public render() {
    return (
      <div className={`SocialIcon`}>
        {this[this.props.type]}
      </div>
    )
  }
}

export default SocialIcon;