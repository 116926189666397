import * as React from 'react';
import './MainContent.css';
import {IMainContentProps, IMainContentState} from "./MainContent.interface";
import Container from "../Container/Container";

class MainContent extends React.Component<IMainContentProps, IMainContentState> {

  constructor(props: IMainContentProps) {
    super(props);
    this.state = {
      isHidden: true,
      hasBg: !!props.bgImage
    }
  }

  public componentDidMount(): void {
    setTimeout(() => {
      this.setState({
        isHidden: false,
      })
    }, 10)
  }

  public render() {
    return (
    <div className="MainContent">
      {this.props.bgImage && (
      <div className={`MainContent__image${this.state.isHidden ? ' hidden' : ''}`}
           style={{backgroundImage: `url(${this.props.bgImage})`}}/>
      )}
      <div className={`MainContent__content${
      (this.state.isHidden ? ' hidden' : '') +
      (!this.props.bgImage ? ' MainContent__content--no-bg' : '') +
      (this.props.alignItems === 'center' ? ' MainContent__content--center' : '')
      }`}>
        <Container>
          <div className={`MainContent__wrap`}>
            {this.props.children}
          </div>
        </Container>
      </div>
    </div>
    )
  }
}

export default MainContent;
