import * as React from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import { Transition, TransitionGroup } from 'react-transition-group';
import {appLightbox} from "./app/app-lightbox";
import {appEventDispatcher, appEventListener} from "./app/app-events";
import {preloaderAppReady, preloaderClose} from "./_data/app-preloader";
import Home from "./pages/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import {AppCustomEvents} from "./_enums/app-custom-events.enum";
import {IAppState} from "./App.interface";
import './App.css';
import About from "./pages/About/About";
import Music from "./pages/Music/Music";
import Photos from "./pages/Photos/Photos";
import Bookings from "./pages/Bookings/Bookings";
import SoundCloud from "./components/SoundCloud/SoundCloud";
import {ISoundCloudPlaylistItem} from "./components/SoundCloud/SoundCloud.interface";
import {appMusic} from "./_data/app-music";
import Tracking from "./seo/tracking";
import Events from "./pages/Events/Events";

class App extends React.Component<any, IAppState> {

  public music: ISoundCloudPlaylistItem[] = appMusic;

  constructor(props: any) {
    super(props);
    this.state = {
      lightbox: {
        src: '',
        isOpen: false
      },
      backgroundColour: '#000'
    };

    Tracking.init({
      googleAnalytics: 'G-RNH7L87MFP'
    });

    appLightbox.subscribe(lightbox => {
      this.setState({lightbox})
    });

    appEventListener<string>(AppCustomEvents.BackgroundColourChange).subscribe(value => {
      this.setState({backgroundColour: value})
    });
  }

  public componentDidMount() {
    document.addEventListener('scroll', () => appEventDispatcher('scroll', {
      scrollPosition: window.pageYOffset
    }));

    preloaderClose();
    preloaderAppReady();
  }

  public closeLightbox = () => {
    this.setState({
      lightbox: {
        src: '',
        isOpen: false,
        gallery: undefined
      }
    })
  };

  public render() {
    return (
    <BrowserRouter>
      <div className="App">
        <div className={`App__bg`}  style={{backgroundColor: this.state.backgroundColour}}/>
        <div className="App__wrap">
          <Navbar/>
          <Switch>
            <Route path="/" exact={true} component={Home}/>
            <Route path="/music" exact={true} component={Music}/>
            <Route path="/about" exact={true} component={About}/>
            <Route path="/photos" exact={true} component={Photos}/>
            <Route path="/bookings" exact={true} component={Bookings}/>
            <Route path="/events" exact={true} component={Events}/>
            <Redirect from='*' to='/'/>
          </Switch>
        </div>
        <SoundCloud playlist={this.music}/>

        {/*<Lightbox src={this.state.lightbox.src}*/}
        {/*          onClose={this.closeLightbox}*/}
        {/*          isOpen={this.state.lightbox.isOpen}/>*/}
      </div>
    </BrowserRouter>
    )
  }
}

export default App;
