"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
var React = require("react");
var app_lightbox_1 = require("../../app/app-lightbox");
require("./Image.css");
var Image = /** @class */ (function (_super) {
    __extends(Image, _super);
    function Image(props) {
        var _this = _super.call(this, props) || this;
        _this.onClick = function () {
            if (!_this.props.fullSizeSrc) {
                return;
            }
            app_lightbox_1.triggerLightbox(_this.props.fullSizeSrc);
        };
        return _this;
    }
    Image.prototype.render = function () {
        var props = this.props;
        return (React.createElement("img", { onClick: this.onClick, src: props.src, alt: props.alt, className: "Image " + (props.fullSizeSrc ? 'Image--zoom' : '') + " " + (props.className ? props.className : '') }));
    };
    return Image;
}(React.Component));
exports["default"] = Image;
