"use strict";
exports.__esModule = true;
exports.loadScript = void 0;
function loadScript(src, async) {
    if (async === void 0) { async = true; }
    var script = document.createElement('script');
    script.src = src;
    script.async = async;
    document.body.appendChild(script);
}
exports.loadScript = loadScript;
