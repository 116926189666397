"use strict";
exports.__esModule = true;
var HeadingSizes;
(function (HeadingSizes) {
    HeadingSizes["Size0"] = "0";
    HeadingSizes["Size1"] = "1";
    HeadingSizes["Size2"] = "2";
    HeadingSizes["Size3"] = "3";
    HeadingSizes["Size4"] = "4";
})(HeadingSizes = exports.HeadingSizes || (exports.HeadingSizes = {}));
var HeadingColours;
(function (HeadingColours) {
    HeadingColours["White"] = "";
    HeadingColours["Yellow"] = "yellow";
    HeadingColours["Blue"] = "blue";
})(HeadingColours = exports.HeadingColours || (exports.HeadingColours = {}));
