import * as React from 'react';
import './SoundCloudPlay.css';
import { ISoundCloudPlayProps } from './SoundCloudPlay.interface';

class SoundCloudPlay extends React.Component<ISoundCloudPlayProps> {

  constructor(props: ISoundCloudPlayProps) {
    super(props);
  }

  public playPause = () => {
    if (this.props.loaded) {
      this.props.clickFn();
    }
  };

  public render() {
    return (
      <div className={`SoundCloudPlay ${this.props.isPlaying ? 'playing' : ''} ${!this.props.loaded ? 'loading' : ''}`}
           onClick={this.playPause}>
        <div className="SoundCloudPlay__icon1"/>
        <div className="SoundCloudPlay__icon2"/>
      </div>
    )
  }
}

export default SoundCloudPlay;