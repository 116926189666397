import * as React from 'react';
import {ISoundCloudProps, ISoundCloudState} from './SoundCloud.interface';
import * as SoundcloudWidget from 'soundcloud-widget';
import SoundCloudProgress from './SoundCloudProgress/SoundCloudProgress';
import SoundCloudPlay from './SoundCloudPlay/SoundCloudPlay';
import './SoundCloud.css';
import { appSoundCloud, triggerSoundCloud } from './app-soundcloud';
import { IAppSoundCloudState } from './app-soundcloud.interface';
import Close from '../Close/Close';

class SoundCloud extends React.Component<ISoundCloudProps, ISoundCloudState> {

  public widget: any;
  public sound: any;

  public readonly playerId = `sc-player`;

  constructor(props: ISoundCloudProps) {
    super(props);
    this.state = {
      id: '',
      loaded: false,
      progressPosition: 0,
      loadProgress: 0,
      isPlaying: false,
      isVisible: false,
      title: 'Loading...'
    }
  }

  public componentDidMount() {
    appSoundCloud.subscribe((soundCloud: IAppSoundCloudState) => {
      if (!this.state.id) {
        this.setPlayerState(soundCloud, () => {
          this.widget = new SoundcloudWidget(this.playerId);
          this.onReady(soundCloud.shouldPlay);
          this.onLoadProgress();
          this.onPlayProgress();
          this.onFinish();
          this.onPause();
          this.onPlay();
        });
      } else {
        this.setPlayerState(soundCloud, () => {
          this.onReload(soundCloud.playingId, soundCloud.secretId)
        })
      }
    });

    const audioPlayerId = localStorage.getItem('audioPlayerId');
    if (audioPlayerId) {
      const soundCloudItem = this.props.playlist.find(item => item.soundcloudId === audioPlayerId);
      if (soundCloudItem) {
        const audioPlayerSId = soundCloudItem.secretId || '';
        const title = soundCloudItem.title;

        let progress: number | string | null = localStorage.getItem('audioPlayerProgress');
        if (progress) {
          progress = parseFloat(progress);
        }

        triggerSoundCloud({
          playingId: audioPlayerId,
          title,
          secretId: audioPlayerSId,
          shouldPlay: false,
          progress: progress as number || 0
        })
      }
    }
  }

  public setPlayerState(state: IAppSoundCloudState, callback?: () => void): void {
    this.setState({
      id: state.playingId,
      secretId: state.secretId || '',
      progressPosition: state.progress || 0,
      loadProgress: 0,
      isVisible: state.isVisible || true,
      title: state.title,
      isPlaying: false
    }, () => {
      if (callback) {
        callback();
      }
    });
  }

  public getUrl(id: string, secretId?: string): string {
    return `https%3A//api.soundcloud.com/tracks/${id}${secretId ? '?secret_token=' + secretId : ''}`;
  }

  public onReady(shouldPlay: boolean) {
    this.widget.on(SoundcloudWidget.events.READY, (x: any) => {
      this.setState({ loaded: true });
      this.widget.getCurrentSound().then((sound: any) => {
        this.sound = sound;
        this.togglePlay(shouldPlay);
      })
    });
  }

  public onReload(id: string, secretId?: string) {
    const opts: any = {
      auto_play: false,
    };

    if (secretId) {
      opts['secret_token'] = secretId;
    }

    this.setState({ loaded: false });
    this.widget.load(this.getUrl(id, secretId)).then(() => {
      this.widget.getCurrentSound().then((sound: any) => {
        this.setState({ loaded: true });
        this.sound = sound;
        this.togglePlay(true);
      });
    });
  }

  public onPlayProgress() {
    this.widget.on(SoundcloudWidget.events.PLAY_PROGRESS, (state: { relativePosition: number }) => {
      if (this.state.isPlaying) {
        if (this.round(state.relativePosition * 100) !== this.round(this.state.progressPosition * 100)) {
          this.setState({ progressPosition: state.relativePosition });
        }
      }
    });
  }

  public onLoadProgress() {
    this.widget.on(SoundcloudWidget.events.LOAD_PROGRESS, (state: { loadProgress: number }) => {
      this.setState({ loadProgress: state.loadProgress })
    });
  }

  public onFinish() {
    this.widget.on(SoundcloudWidget.events.FINISH, () => {
      this.setState({ isPlaying: false });
      this.localStoreEmpty();
    });
  }

  public onPause() {
    this.widget.on(SoundcloudWidget.events.PAUSE, () => {
      if (this.state.isPlaying) {
        this.setState({ isPlaying: false });
      }
    });
  }

  public onPlay() {
    this.widget.on(SoundcloudWidget.events.PLAY, () => {
      this.localStoreUpdate();
    });
  }

  public skipTo = (position: number) => {
    this.widget.seekTo(this.sound.full_duration * position);
  };

  public skipToAndPlay = (shouldPlay: boolean, progress: number) => {
    if (progress || progress === 0) {
      this.skipTo(progress);
    }
    if (!this.state.isPlaying) {
      this.togglePlay(shouldPlay, false);
    }
  };

  public togglePlay = (shouldPlay = true, shouldSkip = true) => {

    if (!this.state.isPlaying && shouldPlay) {
      if (shouldSkip) {
        this.skipTo(this.state.progressPosition)
      }
      this.widget.play();
      this.setState({ isPlaying: true });
    } else {
      this.setState({ isPlaying: false });
      this.widget.pause();
    }
  };

  public localStoreUpdate() {
    if (localStorage.getItem('audioPlayerId') !== this.state.id) {
      localStorage.setItem('audioPlayerId', this.state.id);
    }
  }

  public localStoreEmpty() {
    localStorage.removeItem('audioPlayerId');
    localStorage.removeItem('audioPlayerProgress');
  }

  public round(num: number): number {
    return parseFloat(num.toFixed(1));
  }

  public close = () => {
    this.widget.pause();
    this.setState({ isVisible: false, isPlaying: false });
    this.localStoreEmpty();
  };

  public render() {
    const id = this.state.id;
    const secretId = this.state.secretId;

    return (
      <div className={`SoundCloud ${!this.state.isVisible ? 'hidden' : ''}`}>
        <div className="SoundCloud__player">
          <SoundCloudPlay
            loaded={this.state.loaded}
            isPlaying={this.state.isPlaying}
            clickFn={this.togglePlay}/>

          <SoundCloudProgress
            loaded={this.state.loaded}
            title={this.state.title}
            position={this.state.progressPosition}
            skipTo={this.skipToAndPlay}/>

          <Close clickFn={this.close}/>
        </div>

        {id && (
          <iframe id={this.playerId}
                  className={`SoundCloud__default`}
                  width="100%"
                  height="166"
                  scrolling="no"
                  frameBorder="no"
                  allow="autoplay"
                  src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${id}${secretId ? '?secret_token=' + secretId : ''}`}/>
        )}
      </div>
    )
  }
}

export default SoundCloud;

