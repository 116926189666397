import * as React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import { appEventListener } from '../../app/app-events';
import { IAppEventsScroll } from '../../_interfaces/app-events.interface';
import { INavbarState } from './Navbar.interface';
import NavMobile from './NavMobile/NavMobile';
import { INavItem } from '../../_interfaces/nav-item';
import Burger from '../../components/Burger/Burger';
import { appScrollToTop } from '../../app/app-scroll-to-top';
import Image from '../../components/Image/Image';
import SocialList from '../../components/SocialList/SocialList';
import { appData } from '../../_data/app-data';
import Container from '../../components/Container/Container';

class Navbar extends React.Component<any, INavbarState> {

  private navItems: INavItem[] = [
    {
      scrollToTop: true,
      title: 'Home',
      url: '/'
    },
    {
      scrollToTop: true,
      title: 'Music',
      url: '/music'
    },
    {
      scrollToTop: true,
      title: 'About',
      url: '/about'
    },
    {
      scrollToTop: true,
      title: 'Photos',
      url: '/photos'
    },
    {
      title: 'Merchandise',
      url: 'https://shabbadan.bigcartel.com',
      isExternal: true
    },
    {
      scrollToTop: true,
      title: 'Events',
      url: '/events'
    },
    {
      title: 'Bookings',
      url: 'http://www.cfagency.co.uk/',
      isExternal: true
    }
  ];

  protected constructor(props: any) {
    super(props);
    this.state = {
      isScrolled: false,
      mobileOpen: false
    }
  }

  public toggleMobileMenu = () => {
    this.setState({
      mobileOpen: !this.state.mobileOpen
    })
  };

  public componentDidMount() {
    appEventListener<IAppEventsScroll>('scroll').subscribe(event => {
      if (event.scrollPosition >= 40 && !this.state.isScrolled) {
        this.setState({ isScrolled: true });
      } else if (event.scrollPosition < 40 && this.state.isScrolled) {
        this.setState({ isScrolled: false });
      }
    });
  }

  public mobileNavClick = () => {
    appScrollToTop();
    this.toggleMobileMenu();
  };

  public render() {
    return (
      <nav className={`Navbar ${this.state.isScrolled ? 'scrolled' : ''}`}>
        <div className="Navbar__bg">
          <Container modifiers={['full']}>
            <div className="Navbar__layout">
              <div className="Navbar__left">
                <NavLink activeClassName="active" to={'/'}>
                  <Image src="/assets/img/logo-nav.png"
                         alt="MC Shabba"
                         className="Navbar__logo"/>
                </NavLink>

                <ul className="Navbar__nav">
                  {this.navItems.map((item, i) => (
                    <li className="Navbar__nav__item" key={i}>
                      {!item.isExternal && (
                        <NavLink activeClassName={`active ${item.noActive ? 'no-active' : ''}`}
                                 exact={true}
                                 onClick={appScrollToTop}
                                 to={item.url}>
                          <span className="Navbar__nav__item__content">{item.title}</span>
                        </NavLink>
                      )}

                      {item.isExternal && (
                        <a href={item.url} target="_blank">
                          <span className="Navbar__nav__item__content">{item.title}</span>
                        </a>
                      )}
                    </li>
                  ))}
                </ul>

                <NavMobile isOpen={this.state.mobileOpen}>
                  <ul className="Navbar__nav-mobile">
                    {this.navItems.map((item, i) => (
                      <li className="Navbar__nav-mobile__item" key={i}>
                        {!item.isExternal && (
                          <NavLink activeClassName={`active ${item.noActive ? 'no-active' : ''}`}
                                   exact={true}
                                   onClick={this.mobileNavClick}
                                   to={item.url}>{item.title}</NavLink>
                        )}

                        {item.isExternal && (
                          <a href={item.url} target="_blank">{item.title}</a>
                        )}
                      </li>
                    ))}
                  </ul>

                  <SocialList {...appData.social}/>
                </NavMobile>
              </div>

              <div className="Navbar__right">
                <Burger clickFn={this.toggleMobileMenu}
                        isOpen={this.state.mobileOpen}/>

                <SocialList {...appData.social}/>
              </div>
            </div>
          </Container>
        </div>
      </nav>
    )
  }

}

export default Navbar;
