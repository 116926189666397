import * as React from 'react';
import {appEventDispatcher} from "../../app/app-events";
import {AppCustomEvents} from "../../_enums/app-custom-events.enum";
import * as meta from "../Photos/Photos.meta";
import Page from "../../components/Page/Page";
import MainContent from "../../components/MainContent/MainContent";
import './Photos.css';
import Heading from "../../components/Heading/Heading";
import Gallery from "../../components/Gallery/Gallery";
import {IImage} from "../../_interfaces/image.interface";

class Photos extends React.Component {

  public photos: IImage[] = [];

  constructor(props: any) {
    super(props);

    [
      '001',
      '002',
      '003',
      '004',
      '005',
      '006',
      '007',
      '008',
      '009',
    ].forEach(num => {
      this.photos.push({
        src: `assets/img/photos/mc-shabba-${num}--thumb.jpg`,
        fullSizeSrc: `assets/img/photos/mc-shabba-${num}.jpg`,
        alt: 'MC Shabba'
      })
    })
  }

  public componentDidMount(): void {
    appEventDispatcher(AppCustomEvents.BackgroundColourChange, '#15211d');
  }

  public render() {
    return (
    <div className="Photos">
      <Page {...meta.default}>
        <MainContent bgImage={'assets/img/bg/photos.png'}>

          <Heading text={`Photos`} level={`h1`}/>
          <Gallery images={this.photos}/>

        </MainContent>
      </Page>
    </div>
    )
  }
}

export default Photos;
