"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
var React = require("react");
require("./Heading.css");
var Heading_enum_1 = require("./Heading.enum");
var colours_enum_1 = require("../../_enums/colours.enum");
var Heading = /** @class */ (function (_super) {
    __extends(Heading, _super);
    function Heading(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            text: props.text,
            size: props.size || Heading_enum_1.HeadingSizes.Size0,
            level: props.level || 'h2',
            colour: props.colour || colours_enum_1.Colours.White
        };
        return _this;
    }
    Heading.prototype.render = function () {
        var H = this.state.level || 'h2';
        var _a = this.state, size = _a.size, colour = _a.colour;
        var shadow = this.props.shadow;
        return (React.createElement(H, { className: "Heading Heading--" + colour + " Heading--size" + size + " " + (shadow ? 'Heading--shadow' : '') },
            this.props.prefix && (React.createElement("span", { className: "Heading__prefix" }, this.props.prefix)),
            React.createElement("span", { className: "Heading__content" }, this.props.text)));
    };
    return Heading;
}(React.Component));
exports["default"] = Heading;
