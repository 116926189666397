"use strict";
exports.__esModule = true;
var App_settings_1 = require("../App.settings");
var Seo = /** @class */ (function () {
    function Seo(page) {
        // Defaults
        this.domain = App_settings_1.defaultMeta.domain;
        this.titlePostfix = App_settings_1.defaultMeta.titlePostfix;
        this.ogImage = App_settings_1.defaultMeta.ogImage;
        this.title = page.title;
        this.description = page.description;
        this.uri = page.uri;
        if (!page || !page.og) {
            this.og = {
                image: ''
            };
        }
        else {
            this.og = page.og;
        }
        if (!this.og.image) {
            this.og.image = this.ogImage;
        }
        this.og.image = "" + this.domain + this.og.image;
        this.url = "" + this.domain + page.uri;
        this.title = page.title.indexOf('|') === -1 ? "" + page.title + this.titlePostfix : page.title;
    }
    return Seo;
}());
exports.Seo = Seo;
