import ReactPixel from 'react-facebook-pixel';
import {GoogleAnalytics} from './google-analytics';
import {appData} from '../_data/app-data';


let googleAnalytics: string | undefined;
let facebookPixel: string | undefined;

export interface ITrackingKeys {
  googleAnalytics?: string;
  facebookPixel?: string;
}

let gaInstance: GoogleAnalytics;

export default class Tracking {
  public static init(opts: ITrackingKeys): void {
    if (window.location.hostname.indexOf(appData.domain) > -1) {
      googleAnalytics = opts.googleAnalytics;
      facebookPixel = opts.facebookPixel;
    }

    if (googleAnalytics) {
      gaInstance = new GoogleAnalytics(googleAnalytics);
      gaInstance.pageView();
    }

    if (facebookPixel) {
      ReactPixel.init(facebookPixel);
    }
  }

  public static pageView(slug: string): void {
    // if (googleAnalytics) {
    //
    // }

    if (facebookPixel) {
      ReactPixel.pageView();
    }
  }

  public static contentViewedSinlge(pixelId: string) {
    if (window.location.hostname.indexOf(appData.domain) > -1) {
      ReactPixel.trackSingle(pixelId, 'ViewContent', {});
    }
  }

  public static pageViewSingle(pixelId: string) {
    if (window.location.hostname.indexOf(appData.domain) > -1) {
      ReactPixel.trackSingle(pixelId, 'PageView', {});
    }
  }

  public static contentViewed(name: string, id: string | string[], type: string) {
    ReactPixel.track('ViewContent', {
      content_name: name,
      content_ids: typeof id === 'string' ? [id] : id,
      content_type: type
    });
  }

  public static eventViewed(name: string, id: string | string[]) {
    Tracking.contentViewed(name, id, 'product');
  }

}
