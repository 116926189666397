const preloader = document.querySelector('#appPreloader');

export function preloaderAppReady() {
  if (preloader) {
    preloader.classList.add('opaque');
  }
}

export function preloaderClose() {
  if (preloader) {
    preloader.classList.add('hidden');

    setTimeout(() => {
      preloader.classList.add('hide');
    },2000)
  }
}

export function preloaderOpen() {
  if (preloader) {
    preloader.classList.remove('hidden');
    preloader.classList.remove('hide');
  }
}

(document as any).xxx = preloaderOpen;