"use strict";
exports.__esModule = true;
var app_data_1 = require("./_data/app-data");
exports.defaultMeta = {
    domain: "http://www." + app_data_1.appData.domain,
    titlePostfix: ' | MC Shabba',
    ogImage: '/assets/img/og-image.png'
};
exports.sitemap = {
    isRoot: true,
    title: 'x',
    description: 'x',
    uri: 'x',
    children: []
};
