import * as React from 'react';
import { ILightboxProps } from './Lightbox.interface';

import './Lightbox.css';
import Close from '../Close/Close';

class Lightbox extends React.Component<ILightboxProps> {

  constructor(public props: ILightboxProps) {
    super(props);
  }

  public prev = () => {
    if (!this.props.onPrev) {
      return
    }
    this.props.onPrev();
  };

  public next = () => {
    if (!this.props.onNext) {
      return
    }
    this.props.onNext();
  };

  public close = () => {
    if (!this.props.onClose) {
      return
    }
    this.props.onClose();
  };

  public render() {
    return this.props.isOpen && (
      <div className={`Lightbox`}>
        <div className="Lightbox__overlay">
          <div className="Lightbox__overlay__bg" onClick={this.close}/>

          <img src={this.props.src}/>

          {!!this.props.onPrev && (
            <div className="Lightbox__overlay__prev" onClick={this.prev}>
              <svg viewBox="0 0 13.66 21.66">
                <polygon className="cls-1" points="2.83 21.66 0 18.83 8 10.83 0 2.83 2.83 0 13.66 10.83 2.83 21.66"/>
              </svg>
            </div>
          )}

          {!!this.props.onNext && (
            <div className="Lightbox__overlay__next" onClick={this.next}>
              <svg viewBox="0 0 13.66 21.66">
                <polygon className="cls-1" points="2.83 21.66 0 18.83 8 10.83 0 2.83 2.83 0 13.66 10.83 2.83 21.66"/>
              </svg>
            </div>
          )}

          {!!this.props.onClose && (
            <div className="Lightbox__overlay__close">
              <Close clickFn={this.close}/>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Lightbox;