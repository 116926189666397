import * as React from 'react';
import { IGalleryProps, IGalleryState } from './Gallery.interface';

import './Gallery.css';
import Lightbox from '../Lightbox/Lightbox';

class Gallery extends React.Component<IGalleryProps, IGalleryState> {

  constructor(props: IGalleryProps) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  public openAtIndex(index: number): void {
    this.setState({ isOpen: true, photoIndex: index });
  }

  public close = (): void => {
    this.setState({ isOpen: false });
  };

  public next = () => {
    const index = this.props.images.length - 1 === this.state.photoIndex ? 0 : this.state.photoIndex + 1;
    this.setState({ photoIndex: index });
  };

  public prev = () => {
    const index = this.state.photoIndex === 0 ? this.props.images.length - 1 : this.state.photoIndex - 1;
    this.setState({ photoIndex: index });
  };

  public render() {
    const { photoIndex, isOpen } = this.state;
    const images = this.props.images;

    return (
      <div className="Gallery">
        <div className="Gallery__list">
          {this.props.images.map((image, i) => (
            <div className="Gallery__column"
                 key={i}
                 onClick={this.openAtIndex.bind(this, i)}>
              <img src={image.src}/>
            </div>
          ))}
        </div>

        {images.length && (
          <Lightbox src={images[photoIndex].fullSizeSrc || ''}
                    isOpen={isOpen}
                    onNext={this.next}
                    onPrev={this.prev}
                    onClose={this.close}/>
        )}
      </div>
    );
  }
}

export default Gallery;
