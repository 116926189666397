import * as React from 'react';
import './Burger.css';
import { IBurgerProps } from './Burger.interface';

class Burger extends React.Component<IBurgerProps> {

  protected constructor(props: IBurgerProps) {
    super(props)
  }

  public render() {
    return (
      <div className={`Burger ${this.props.isOpen? 'open': ''}`} onClick={this.props.clickFn}>
        <div className="Burger__layer1"/>
        <div className="Burger__layer2"/>
        <div className="Burger__layer3"/>
      </div>
    );
  }
}

export default Burger;