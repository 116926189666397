import { IEvent } from '../_interfaces/event.interface';
import moment from 'moment';
import { Moment } from 'moment';
import { DateHelpers } from './date-helpers';

export class EventHelpers {

  public dateHelpers = new DateHelpers;

  public utcDate = 'YYYY-MM-DD';
  public utcDateTime = 'YYYY-MM-DDTHH:ss';

  public today = moment().format(this.utcDate);

  constructor(private event: IEvent) {
  }

  public moment(datetime: string): Moment {
    return moment(datetime, this.utcDateTime);
  }

  public momentStart(): Moment {
    return this.moment(this.event.times.start);
  }

  public momentEnd(): Moment {
    return this.moment(this.event.times.end);
  }

  public getDates(time = false): string {
    if (this.event.times.start === '' &&  this.event.times.end === '') {
      return '';
    }

    const format = time ? 'DD.MM.YYYY [at] h:mma' : 'DD.MM.YYYY';

    const duration = moment
      .duration(this.momentEnd().diff(this.momentStart()))
      .asHours();

    if (duration >= 24) {
      return this.momentStart().format('DD.MM.YYYY') + ' to ' + this.momentEnd().format('DD.MM.YYYY')
    }

    return moment(this.event.times.start).format(format);
  }

  public isFuture(): boolean {
    return (this.event.times.start === '' && this.event.times.end === '') || this.dateHelpers.isFuture(this.event.times.start);
  }

  public isPast(): boolean {
    return !this.isFuture();
  }

}
