import {loadScript} from '../functions/load-script/load-script';

declare global {
  interface Window { gtag: any; dataLayer: any; }
}

export class GoogleAnalytics {
  constructor(private id: string) {
    loadScript(`https://www.googletagmanager.com/gtag/js?id=${this.id}`);
    this.init();
  }

  public pageView(): void {
    if (!window['gtag'])  {
      return
    }

    window['gtag']('event', 'page_view');
  }

  private init(): void {
    window['dataLayer'] = window['dataLayer'] || [];
    /* tslint:disable-no-string-literal */
    window['gtag'] = function() {
      window['dataLayer'].push(arguments);
    }
    window['gtag']('js', new Date());

    window['gtag']('config', this.id, {
      send_page_view: false
    });
  }
}
