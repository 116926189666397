import * as React from 'react';
import * as meta from "../Events/Events.meta";
import MainContent from "../../components/MainContent/MainContent";
import Page from "../../components/Page/Page";
import './Events.css';
import {appEventDispatcher} from "../../app/app-events";
import {AppCustomEvents} from "../../_enums/app-custom-events.enum";
import Heading from "../../components/Heading/Heading";
import {IEvent} from "../../_interfaces/event.interface";
import Button from "../../components/Button/Button";
import {Colours} from "../../_enums/colours.enum";
import {HeadingSizes} from "../../components/Heading/Heading.enum";
import {EventHelpers} from "../../helpers/event-helpers";

class Events extends React.Component<any, any> {

  public events: IEvent[] = [
    {
      title: 'River Monster 16+ Boat Party',
      image: '/assets/img/events/2020-river-monster-boat-16.jpg',
      ticketLink: 'https://www.eventbrite.co.uk/e/shabba-d-presents-an-exclusive-16-vip-river-monster-boat-party-tickets-98894707733',
      times: {
        start: '2020-05-16T14:00',
        end: '2020-05-16T18:00'
      }
    },
    {
      title: 'River Monster 18+ Boat Party',
      image: '/assets/img/events/2020-river-monster-boat-18.jpg',
      ticketLink: 'http://bit.ly/18PlusBoat',
      times: {
        start: '2020-05-16T19:00',
        end: '2020-05-16T23:00'
      }
    }
  ].filter(event => {
    const eventHelper = new EventHelpers(event);
    return eventHelper.isFuture();
  });

  constructor(props: any) {
    super(props);
  }

  public componentDidMount(): void {
    appEventDispatcher(AppCustomEvents.BackgroundColourChange, '#1f2838');


  }

  public render() {
    return (
    <div className={`Events`}>
      <Page {...meta.default}>
        <MainContent>
          <Heading text={`Events`} level={`h1`}/>

          {this.events.length > 0 ? (
          <div className={`Events__list`}>
            {this.events.map((event, index) => {
              return (<div className={`Events__list__event`} key={index}>
                <a href={event.ticketLink} target={`_blank`}>
                  <img className={`Events__list__event-image`} src={event.image}/>
                  <Heading className={`Events__list__event-heading`} text={event.title} level={`h2`} size={HeadingSizes.Size3}/>
                  <Button content={`Buy tickets`} colour={Colours.Yellow}/>
                </a>
              </div>)
            })}
          </div>
          ) : <p>Sorry, there are currently no events. Check back soon.</p>}
        </MainContent>
      </Page>
    </div>
    )
  }
}

export default Events;
