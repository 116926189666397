import * as React from 'react';
import {appEventDispatcher} from "../../app/app-events";
import {AppCustomEvents} from "../../_enums/app-custom-events.enum";
import * as meta from "../Bookings/Bookings.meta";
import Page from "../../components/Page/Page";
import MainContent from "../../components/MainContent/MainContent";
import './Bookings.css';
import Heading from "../../components/Heading/Heading";
import SocialList from "../../components/SocialList/SocialList";
import {appData} from "../../_data/app-data";

class Bookings extends React.Component {

  constructor(props: any) {
    super(props);
  }

  public componentDidMount(): void {
    appEventDispatcher(AppCustomEvents.BackgroundColourChange, '#333632');
  }

  public render() {
    return (
    <div className="Bookings">
      <Page {...meta.default}>
        <MainContent bgImage={'assets/img/bg/bookings.png'}>

          <Heading text={`Bookings`} level={`h1`}/>

          <p>MC Shabba is available for bookings across the UK and internationally. To book Shabba please get in touch using the details below:</p>

          <p>
            <b>Email 1: </b>
            <a className={`Bookings__link`} href={`mailto:andy@cfagency.co.uk`}>andy@cfagency.co.uk</a>
          </p>

          <p>
            <b>Email 2: </b>
            <a className={`Bookings__link`} href={`mailto:stickydarave@hotmail.co.uk`}>stickydarave@hotmail.co.uk</a>
          </p>

          <p>Alternatively, you can get in touch through the following social media channels:</p>

          <SocialList {...appData.social}/>

        </MainContent>
      </Page>
    </div>
    )
  }
}

export default Bookings;
