import * as React from 'react';
import SocialIcon from '../SocialIcon/SocialIcon';
import { SocialIconTypes } from '../SocialIcon/SocialIcon.enum';
import './SocialList.css';
import { ISocialListProps } from './SocialList.interface';

class SocialList extends React.Component<ISocialListProps> {

  constructor(props: ISocialListProps) {
    super(props);
  }

  public render() {
    return (
      <ul className="SocialList">
        {this.props.facebook && (
          <li className="SocialList__item">
            <a href={this.props.facebook} target="_blank" title="Join us on Facebook">
              <SocialIcon type={SocialIconTypes.Facebook}/>
            </a>
          </li>
        )}

        {this.props.instagram && (
          <li className="SocialList__item">
            <a href={this.props.instagram} target="_blank" title="Join us on Instagram">
              <SocialIcon type={SocialIconTypes.Instagram}/>
            </a>
          </li>
        )}

        {this.props.twitter && (
          <li className="SocialList__item">
            <a href={this.props.twitter} target="_blank" title="Join us on Twitter">
              <SocialIcon type={SocialIconTypes.Twitter}/>
            </a>
          </li>
        )}

        {this.props.soundcloud && (
          <li className="SocialList__item">
            <a href={this.props.soundcloud} target="_blank" title="Join us on Twitter">
              <SocialIcon type={SocialIconTypes.Soundcloud}/>
            </a>
          </li>
        )}
      </ul>
    )
  }
}

export default SocialList;