import { Subject } from 'rxjs/internal/Subject';
import { IAppLightboxState } from '../_interfaces/app-lightbox.interface';

// Observable
export const appLightbox: Subject<IAppLightboxState> = new Subject();

export function triggerLightbox(src: string): void {
  appLightbox.next({
    src,
    isOpen: true
  });
}
