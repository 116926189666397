"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
var React = require("react");
require("./Button.css");
var react_router_dom_1 = require("react-router-dom");
var app_scroll_to_top_1 = require("../../app/app-scroll-to-top");
var Button = /** @class */ (function (_super) {
    __extends(Button, _super);
    function Button() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Button.prototype.render = function () {
        return (React.createElement("div", { className: "Button Button--" + this.props.colour },
            this.props.internalUrl && (React.createElement(react_router_dom_1.NavLink, { to: this.props.internalUrl, onClick: app_scroll_to_top_1.appScrollToTop }, this.props.content)),
            this.props.externalUrl && (React.createElement("a", { href: this.props.externalUrl, target: "_blank" }, this.props.content)),
            !this.props.externalUrl && !this.props.internalUrl && (React.createElement("span", null, this.props.content))));
    };
    return Button;
}(React.Component));
exports["default"] = Button;
