import * as React from 'react';
import MetaTags from 'react-meta-tags';
import { IPageProps } from './Page.interface';
import './Page.css';
import { Seo } from '../../seo/seo';
import Tracking from '../../seo/tracking';

class Page extends React.Component<IPageProps> {

  protected ogImageDefault = `/assets/img/og-image.png`;

  protected constructor(props: IPageProps) {
    super(props);
  }

  public componentDidMount() {
    Tracking.pageView(this.props.uri);
  }

  public render() {
    const {url, og, title, description} = new Seo(this.props);
    const ogImage = og.image;

    const style: {
      backgroundImage?: string;
      backgroundSize?: string;
      backgroundColour?: string;
    } = {};
    if (this.props.bg ) {
      if (this.props.bg.image) {
        style.backgroundImage = `url(${this.props.bg.image})`;
      }
      if (this.props.bg.size) {
        style.backgroundSize = this.props.bg.size;
      }
      if (this.props.bg.colour) {
        style.backgroundColour = this.props.bg.colour;
      }
    }

    return (
      <div className={`Page`}>
        <div className={`Page__bg ${this.props.bg && this.props.bg.blur ? 'blur': ''}`} style={style}/>
        <MetaTags>
          <title>{title}</title>
          <meta name="description" content={description}/>

          <meta property="og:locale" content="en_GB"/>
          <meta property="og:type" content="website"/>
          <meta property="og:title" content={title}/>
          <meta property="og:description" content={description}/>
          <meta property="og:url" content={url}/>
          <meta property="og:site_name" content={title}/>
          <meta property="og:image" content={ogImage}/>

          <meta name="twitter:card" content="summary"/>
          <meta name="twitter:description" content={description}/>
          <meta name="twitter:title" content={title}/>
          <meta name="twitter:image" content={ogImage}/>
        </MetaTags>
        {this.props.children}
      </div>
    )
  }
}

export default Page;
