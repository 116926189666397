import { Subject } from 'rxjs/internal/Subject';
import { IAppSoundCloudState } from './app-soundcloud.interface';

// Observable
export const appSoundCloud: Subject<IAppSoundCloudState> = new Subject();

let state: IAppSoundCloudState;

export function triggerSoundCloud(opts: IAppSoundCloudState): void {
  state = {
    ...opts,
    secretId: opts.secretId || '',
    isVisible: true,
    progress: opts.progress || 0
  };
  appSoundCloud.next(state);
}

export function closeSoundCloud(): void {
  state = {
    shouldPlay: false,
    playingId: '',
    isVisible: false,
    title: ''
  };
  appSoundCloud.next(state);
}
