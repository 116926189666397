import * as React from 'react';
import './NavMobile.css';
import { INavMobileProps } from './NavMobile.interface';

class NavMobile extends React.Component<INavMobileProps, any> {

  public render() {
    return (
      <div className={`NavMobile ${this.props.isOpen ? '' : 'closed'}`}>
        <div className="NavMobile__content">
          {this.props.children}
        </div>
      </div>
    )
  }

}

export default NavMobile;
