import { Observable } from 'rxjs/internal/Observable';
import { filter, map } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';

export interface IAppEvent<T> {
  type: string;
  payload: T;
}

// Observable
export const appEvents: Subject<IAppEvent<any>> = new Subject();

// Event listener
export function appEventListener<T>(type: string): Observable<T> {
  return appEvents
    .pipe(filter(result => type === result.type))
    .pipe(map(result => result.payload));
}

// Dispatch event
export function appEventDispatcher<T>(type: string, payload: T): void {
  appEvents.next({ type, payload });
}