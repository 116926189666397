import * as React from 'react';
import {appEventDispatcher} from "../../app/app-events";
import {AppCustomEvents} from "../../_enums/app-custom-events.enum";
import * as meta from "../About/About.meta";
import Page from "../../components/Page/Page";
import MainContent from "../../components/MainContent/MainContent";
import './About.css';
import Heading from "../../components/Heading/Heading";

class About extends React.Component {

  constructor(props: any) {
    super(props);
  }

  public componentDidMount(): void {
    appEventDispatcher(AppCustomEvents.BackgroundColourChange, '#183034');
  }

  public render() {
    return (
    <div className="About">
      <Page {...meta.default}>
        <MainContent bgImage={'assets/img/bg/about2.png'}>
          <div className={`About__content`}>
            <Heading text={`Biography`} level={`h1`}/>
            <p>Shabba started his MC career in the early 1990s, starting with reggae music, local ragga inspired sound,
              UK and Jamaican sound systems.</p>

            <p>His music involvement started in hardcore and jungle through friends who ran Weekend Rush 92.5FM a
              prolific pirate radio station. The name Shabba evolved following his return from a holiday in Jamaica with
              his friends jokingly calling him Shabba B - probably because at the time Shabba Ranks was a big name in
              reggae music. Shabba quickly rose to fame in East London through Weekend Rush. He started his music stint
              answering the station phones and running errands, then eventually had a chance to MC on the station
              itself. By then his popularity continued to grow in the music scene, and Shabba moved onto Defection 89.4
              in The early 90s By the Mid 90s he moved on to Kick FM 92.3, receiving his first bookings as an MC and
              resident at Telepathy every Friday and Sunday for XXX (amount of time).</p>

            <p>In 1996 Shabba went onto work with the biggest drum n bass and jungle station of the time Kool FM in
              London, represented by the agency Jungle Fever. This is when things really started to take off... Shabba
              mc'ed all over the UK and performed across Europe with a growing scene and following. New doors opened
              global doors with his first Canadian booking in 1999, followed by performances in the US. Shabba started
              bringing jungle music to other parts of Europe renowned for Garage music like Cyprus (Ayia Napa) and
              nearby islands, hosting events of his own with likes of Telepathy, Breaking Science and Matrix.</p>

            <p>In 2003 Shabba, MC Skibadee and Paul Ibiza collaborated to form the biggest ever MC rave of all time MC
              Convention, and his record label Highly Blessed. This took the jungle world by storm, with parties
              worldwide and a huge life changing following. Shabba moved onto Urban Agency, creating personal raves
              with Higher Level, Sticky and Girls Don't Pay events regularly over the years.</p>

            <p>In 2011 Shabba, MC Skibadee, Harry Shotta, MC Stormin, DJ Phantasy and Macky Gee created the drum n bass
              super group SASASAS, taking the underground streets sounds of Jump Up drum n bass to the masses. Their
              performances are a wall of energy that must be witnessed live and have managed to capture a significant
              and growing global following. With a fan base that ranges from ages 14-40 plus the group's appeal is
              growing stronger and stronger, and will continue in the foreseeable future.</p>
          </div>
        </MainContent>
      </Page>
    </div>
    )
  }
}

export default About;
